









































import { computed, defineComponent, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

interface FormError {
  error: string;
}

export default defineComponent({
  components: {
    OSpecializationAdd: () => import('@/components/organisms/globalTools/specialization/o-specialization-add.vue'),
  },

  setup(props, { root }) {

    const model = reactive<{
      data: any;
    }>({
      data: {
        languageVersion: 'polish'
      }
    })

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): FormError[] => {
        const errors: FormError[] = []

        if (!model.data.name) errors.push({ error: 'Podaj nazwę specjalizacji.' })

        return errors
      }),
    })

    const isFormValid = computed(() => !state.errors.length)

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance


      const data = {
        name: model.data.name,
        languageVersion: model.data.languageVersion
      }

      state.loading = true

      axiosInstance
        .post('specialization', data)
        .then(() => {

          state.success = true
          model.data = {
            languageVersion: 'polish'
          }
        })
        .catch(error => {
          state.error = error.response.status
        })
        .finally(() => state.loading = false)
    }

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 409: return 'Specjalizacja już istnieje'
        case 500: return `${root.$tc('layout.errors.500')}`
        default: return `${root.$tc('layout.errors.default')}`
      }
    }

    return {
      model,
      state,
      isFormValid,
      onSubmit,
      getErrorMessage
    }
  }
})
